<template>
    <div>
        <card-header title="Personal Itinerary" icon="fa-calendar-alt"/>

        <card-body>
            <card-list>
                <registration-list-item :card="card" :registration="registration"/>
                <subheader-list-item title="Trip Days" icon="fas fa-calendar-alt"></subheader-list-item>
                <day-list-item
                    :card="card"
                    :day="day"
                    v-for="day in days"
                    :active="day.active"
                    @click="$openCard('events', {...props, dayId: day.id}, card)"
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import NavigationListItem from "../../TIER/components/NavigationListItem";
    import TripListItem from "../../components/TripListItem";

    import {client as http} from '../../http_client';
    import CardBody from "../../TIER/components/CardBody";
    import DayListItem from "../../components/DayListItem";
    import RegistrationListItem from "../../components/RegistrationListItem";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {RegistrationListItem, DayListItem, CardBody, TripListItem, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                days: [],
                registration: {
                    user: {}
                }
            }
        },
        methods: {
            loadDays: function(force) {
                this.$emit('loading', true);
                http.get('/api/registrations/' + this.props.registrationId + '/itinerary', {force}).then(response => {
                    for (let i = 0; i < response.data.days.length; i++) {
                        response.data.days[i].active = false;
                    }
                    this.days = response.data.days;
                    this.setActive();
                    this.$emit('loading', false);
                });
            },
            loadRegistration: function() {
                http.get('/api/registrations/' + this.props.registrationId).then(response => {
                    this.registration = response.data;
                });
            },
            setActive: function () {
                let check = (this.child && this.child.definition.component === 'events') ? parseInt(this.child.props.dayId) : null;
                this.days.map(t => t.active = (t.id === check));
            },
            reload() {
                this.loadDays(true);
            },
        },
        watch: {
            'props.registrationId': function() {
                this.loadDays();
            },
            'child': function () {
                this.setActive();
            }
        },
        mounted() {
            this.loadDays(true);
            this.loadRegistration();
        }
    };
</script>

<style lang="scss" scoped>

</style>
